import { type Updater } from "use-immer";
import {
  type Beat,
  type BeatSubdivision,
  type Groove,
  type Note,
  type Sticking,
  type StickingPreset,
  type Subdivision,
  changeSubdivision,
  getDefaultSticking,
  stickings,
} from "@/lib/groove";
import { type Voice } from "@/lib/voice";
import { type VoicePreset } from "@/lib/presets";

export type GrooveHooks = ReturnType<typeof useGrooveEditor>;

export function useGrooveEditor(initialGroove: Groove, onChange: Updater<Groove>) {
  return {
    addBar() {
      onChange((draft) => {
        draft.bars.push({
          beats: Array<Beat>(draft.timeSignatureTop).fill(
            Array<BeatSubdivision>(16 / draft.timeSignatureBottom).fill({
              notes: [],
              sticking: null,
            })
          ),
        });
      });
    },

    duplicateBar(idx: number) {
      onChange((draft) => {
        draft.bars.push(draft.bars[idx]);
      });
    },

    deleteBar(idx: number) {
      onChange((draft) => {
        draft.bars.splice(idx, 1);
      });
    },

    moveBar(fromIdx: number, toIdx: number) {
      onChange((draft) => {
        const bar = draft.bars[fromIdx];

        draft.bars.splice(fromIdx, 1);
        draft.bars.splice(toIdx, 0, bar);
      });
    },

    clearBar(idx: number) {
      onChange((draft) => {
        draft.bars[idx].beats = draft.bars[idx].beats.map((beat) =>
          Array<BeatSubdivision>(beat.length).fill({
            notes: [],
            sticking: null,
          })
        );
      });
    },

    revertBar(idx: number) {
      if (!initialGroove.bars[idx]) return;

      onChange((draft) => {
        draft.bars[idx] = initialGroove.bars[idx];
      });
    },

    setNote(barIdx: number, beatIdx: number, subIdx: number, note: Note) {
      onChange((draft) => {
        const sub = draft.bars[barIdx].beats[beatIdx][subIdx];
        const existingNote = sub.notes.find((n) => n.voiceId === note.voiceId);

        if (existingNote) {
          existingNote.variantId = note.variantId;
        } else {
          sub.notes.push(note);
        }
      });
    },

    moveNote(
      from: {
        barIdx: number;
        beatIdx: number;
        subIdx: number;
        note: Note;
      },
      to: {
        barIdx: number;
        beatIdx: number;
        subIdx: number;
        voiceId: Voice["id"];
      }
    ) {
      onChange((draft) => {
        // remove
        const fromSub = draft.bars[from.barIdx].beats[from.beatIdx][from.subIdx];
        const fromNoteIdx = fromSub.notes.findIndex((n) => n.voiceId === from.note.voiceId);
        if (fromNoteIdx >= 0) fromSub.notes.splice(fromNoteIdx, 1);

        // add
        const toSub = draft.bars[to.barIdx].beats[to.beatIdx][to.subIdx];
        toSub.notes.push({
          voiceId: to.voiceId,
          variantId: null,
        });
      });
    },

    toggleNote(barIdx: number, beatIdx: number, subIdx: number, voice: Voice) {
      onChange((draft) => {
        const sub = draft.bars[barIdx].beats[beatIdx][subIdx];
        const noteIdx = sub.notes.findIndex((note) => note.voiceId === voice.id);

        if (noteIdx >= 0) {
          sub.notes.splice(noteIdx, 1);

          if (sub.notes.length === 0) {
            sub.sticking = null;
          }
        } else {
          sub.notes.push({
            voiceId: voice.id,
            variantId: null,
          });
        }
      });
    },

    setSticking(barIdx: number, beatIdx: number, subIdx: number, sticking: Sticking | null) {
      onChange((draft) => {
        const sub = draft.bars[barIdx].beats[beatIdx][subIdx];
        sub.sticking = sticking;
      });
    },

    toggleSticking(barIdx: number, beatIdx: number, subIdx: number) {
      onChange((draft) => {
        const sub = draft.bars[barIdx].beats[beatIdx][subIdx];

        // we want the "default" sticking to appear first in the rotation
        const defaultSticking = getDefaultSticking(sub.notes);

        const options = [null, defaultSticking, ...stickings.filter((s) => s !== defaultSticking)];

        const nextSticking = options[(options.indexOf(sub.sticking) + 1) % options.length];

        sub.sticking = nextSticking;
      });
    },

    setGrooveSubdivision(subdivision: Subdivision) {
      onChange((draft) => {
        draft.bars.forEach((bar) => {
          bar.beats = bar.beats.map((beat) =>
            changeSubdivision(beat, draft.timeSignatureBottom, subdivision)
          );
        });
      });
    },

    setBeatSubdivision(barIdx: number, beatIdx: number, subdivision: Subdivision) {
      onChange((draft) => {
        draft.bars[barIdx].beats[beatIdx] = changeSubdivision(
          draft.bars[barIdx].beats[beatIdx],
          draft.timeSignatureBottom,
          subdivision
        );
      });
    },

    applyStickingPreset(preset: StickingPreset) {
      onChange((draft) => {
        draft.bars.forEach((bar) => {
          bar.beats.forEach((beat) => {
            beat.forEach((sub, subIdx) => {
              if (!sub.notes.length) return;

              sub.sticking =
                preset === "all_R"
                  ? "R"
                  : preset === "all_L"
                  ? "L"
                  : preset === "alternate"
                  ? subIdx % 2 === 0
                    ? "R"
                    : "L"
                  : preset === "all_off"
                  ? null
                  : sub.sticking;
            });
          });
        });
      });
    },

    applyVoicePreset(voice: Voice, preset: VoicePreset) {
      function set(sub: BeatSubdivision, on: boolean) {
        if (on) {
          if (!sub.notes.some((n) => n.voiceId === voice.id)) {
            sub.notes.push({ voiceId: voice.id, variantId: null });
          }
        } else {
          const idx = sub.notes.findIndex((n) => n.voiceId === voice.id);
          if (idx >= 0) {
            sub.notes.splice(idx, 1);
          }
        }
      }

      onChange((draft) => {
        draft.bars.forEach((bar) => {
          bar.beats.forEach((beat) => {
            beat.forEach((sub, subIdx) => {
              switch (preset) {
                case "all_on":
                  return set(sub, true);
                case "all_off":
                  return set(sub, false);
                case "downbeats":
                  return set(sub, subIdx === 0);
                case "upbeats":
                  return set(sub, beat.length / subIdx === 2);
                default:
                  return;
              }
            });
          });
        });
      });
    },

    setTimeSignatureTop(timeSignatureTop: number) {
      onChange((draft) => {
        draft.bars.forEach((bar) => {
          // ensure that `timeSignatureTop` stays in sync with `bar.beats.length`
          const diff = timeSignatureTop - bar.beats.length;

          if (diff > 0) {
            bar.beats.push(
              ...Array<Beat>(diff).fill(
                Array<BeatSubdivision>(16 / draft.timeSignatureBottom).fill({
                  notes: [],
                  sticking: null,
                })
              )
            );
          } else if (diff < 0) {
            bar.beats = bar.beats.slice(0, diff);
          }
        });

        draft.timeSignatureTop = timeSignatureTop;
      });
    },

    setTimeSignatureBottom(timeSignatureBottom: number) {
      onChange((draft) => {
        draft.timeSignatureBottom = timeSignatureBottom;
      });
    },
  };
}
