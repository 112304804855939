import { type Output, object, array, nullish, string } from "valibot"; // 1.15 kB

const ChartSectionSchema = object({
  form: nullish(string()),
  bars: nullish(string()),
  notes: nullish(string()),
});

export type ChartSection = Output<typeof ChartSectionSchema>;

const ChartLineSchema = object({
  sections: array(ChartSectionSchema),
});

export type ChartLine = Output<typeof ChartLineSchema>;

export const ChartDataSchema = object({
  lines: array(ChartLineSchema),
});

export type ChartData = Output<typeof ChartDataSchema>;
