import abcjs from "abcjs";
import {
  type Beat,
  type BeatSubdivision,
  type Groove,
  type Note,
  type Subdivision,
  getSubdivision,
  stickingName,
} from "@/lib/groove";
import { type Voice, type Variant, variants, voices, getVoice } from "@/lib/voice";
import { sumBy, pad, chunk } from "@/lib/utils";

const ABC_SUBDIVISION: Subdivision = 16;
const ABC_ACCENT = `"^>"`;
const ABC_OPEN = `"^○"`;
const ABC_SPACER = `"^"`;

function getAbcGhostPosition(notes: Note[]) {
  return notes.length > 1 && notes.some((n) => ["k", "t3"].includes(n.voiceId))
    ? `"@-12,-2﹙""@6,-2﹚"`
    : `"@-12,-17﹙""@6,-17﹚"`;
}

export function getAbcNote({ notes, sticking }: BeatSubdivision, topAnnotationHeight: number) {
  const notesWithVoices = notes
    .map((n) => ({
      voice: voices.find((v) => v.id === n.voiceId),
      variant: n.variantId ? variants.find((v) => v.id === n.variantId) : null,
    }))
    .filter((n): n is Required<{ voice: Voice; variant: Variant | null }> => n.voice != null)
    .map((n) => ({
      variant: n.variant,
      abcNote: (n.variant && n.voice.variants[n.variant.id]?.abcNote) || n.voice.abcNote,
    }));

  if (!notesWithVoices.length) return "z";

  const topAnnotations = pad(
    [
      sticking ? `"^${stickingName(sticking)}"` : "",
      notesWithVoices.some((n) => n.variant?.id === "accent") ? ABC_ACCENT : "",
      notesWithVoices.some((n) => n.variant?.id === "open") ? ABC_OPEN : "",
    ].filter(Boolean),
    sticking ? topAnnotationHeight + 1 : topAnnotationHeight,
    ABC_SPACER
  );

  const flamNote = notesWithVoices.find((n) => n.variant?.id === "flam");

  const noteAnnotations = [
    notesWithVoices.some((n) => n.variant?.id === "ghost") ? getAbcGhostPosition(notes) : "",
    flamNote ? `{/${flamNote.abcNote}}` : "",
  ].filter(Boolean);

  const annotations = [...topAnnotations, ...noteAnnotations].join("");

  let abcNotes = notesWithVoices.map((n) => n.abcNote).join("");
  if (notesWithVoices.length > 1) {
    abcNotes = `[${abcNotes}]`;
  }

  return `${annotations}${abcNotes}`;
}

export function getTopAnnotationHeight(bars: Groove["bars"]): number {
  const heights = bars
    .map((bar) =>
      bar.beats.map((beat) =>
        beat.map(({ notes }) => {
          const variantIds = new Set(notes.map((note) => note.variantId).filter(Boolean));

          return sumBy(variants, (v) => (variantIds.has(v.id) && v.position === "top" ? 1 : 0));
        })
      )
    )
    .flat(2);

  return Math.max(...heights);
}

export function grooveToAbc(
  groove: Pick<Groove, "timeSignatureTop" | "timeSignatureBottom" | "bpm" | "bars">
) {
  const abcMappings = [
    "D pedal-hi-hat x",
    "E bass-drum-1",
    "E acoustic-bass-drum",
    "F bass-drum-1",
    "F acoustic-bass-drum",
    "A low-tom",
    "B low-tom",
    "{c/}c acoustic-snare",
    "c acoustic-snare",
    "=c side-stick x",
    "_c electric-snare",
    "^c low-wood-block triangle",
    "d low-mid-tom",
    "^d hi-wood-block triangle",
    "e hi-mid-tom",
    "^e cowbell triangle",
    "f high-tom",
    "^f ride-cymbal-1 x",
    "g closed-hi-hat x",
    "^g open-hi-hat x",
    "a crash-cymbal-1 x",
    "^a crash-cymbal-2 triangle",
  ];

  return (
    `%%stretchlast 1\n` +
    `%%flatbeams 1\n` +
    `%%printTempo false\n` +
    `${abcMappings.map((m) => `%%percmap ${m}`).join("\n")}\n` +
    `M: ${groove.timeSignatureTop}/${groove.timeSignatureBottom}\n` +
    `L: 1/${ABC_SUBDIVISION}\n` +
    `K: C perc\n` +
    `V: 1 stem=up clef=perc\n` +
    `Q: 1/4=${groove.bpm}\n` +
    `|${grooveToAbcNotes(groove)}|\n`
  );
}

export function grooveToAbcNotes(
  groove: Pick<Groove, "timeSignatureTop" | "timeSignatureBottom" | "bpm" | "bars">
) {
  const topAnnotationHeight = getTopAnnotationHeight(groove.bars);

  return groove.bars
    .map((bar) =>
      bar.beats
        .map((beat) => {
          const subdivision = getSubdivision(groove.timeSignatureBottom, beat);

          // handle triplet subdivisions
          if (subdivision % 3 === 0) {
            return tripletToAbc(calcTriplet(beat, groove.timeSignatureBottom), topAnnotationHeight);
          }

          return beat
            .map((sub, subIdx) => {
              if (subIdx > 0 && !sub.notes.length) return "";

              const nextIdx = beat.slice(subIdx + 1).findIndex((s) => s.notes.length > 0);

              const abcNote = getAbcNote(sub, topAnnotationHeight);

              const rawLength =
                nextIdx >= 0 ? nextIdx + 1 : subIdx === beat.length - 1 ? 1 : beat.length - subIdx;

              const abcLength = rawLength * (ABC_SUBDIVISION / subdivision);

              return `${abcNote}${
                abcLength > 1 ? abcLength : abcLength === 1 ? "" : "/".repeat(0.5 / abcLength)
              }`;
            })
            .join("");
        })
        .join(" ")
    )
    .join("|");
}

export function grooveToMidiMetronome(
  groove: Pick<Groove, "timeSignatureTop" | "timeSignatureBottom">,
  metronome: string
) {
  // determine beats per measure
  const beats =
    groove.timeSignatureTop % 3 === 0 && groove.timeSignatureBottom === 8
      ? groove.timeSignatureTop / 3
      : groove.timeSignatureTop;

  // determine how many metronome ("click") notes/subdivisions there are per measure
  const notes = beats * (metronome.match(/d/g) || []).length;

  return [
    // pattern string
    metronome.repeat(beats),
    // note string
    ["76", " 77".repeat(notes - 1)].join(""),
    // volume string
    "60 ".repeat(notes).trimEnd(),
  ].join(" ");
}

export type GrooveRenderMode = "thumbnail" | "view" | "edit";

export function renderGroove(el: HTMLElement, groove: Groove, mode: GrooveRenderMode) {
  const viewportWidth = window.innerWidth;

  const [tune] = abcjs.renderAbc(el, grooveToAbc(groove), {
    paddingtop: getTopAnnotationHeight(groove.bars) > 0 ? 1 : 8,
    paddingbottom: 1,
    paddingleft: 0,
    paddingright: 0,

    add_classes: true,

    staffwidth: el.clientWidth,
    wrap:
      mode === "thumbnail"
        ? undefined
        : { minSpacing: 2, maxSpacing: 2, preferredMeasuresPerLine: 3 },
    scale: mode === "thumbnail" ? 0.25 : calculateScale(viewportWidth, groove),
    format: {
      tripletfont: "Times New Roman 12 italic bold",
      // annotationfont: viewportWidth < 640 ? "Helvetica 10" : "Helvetica 12",
    },
    selectTypes: mode === "edit" ? undefined : false,
  });

  el.style.width = "100%";

  return tune;
}

function calculateScale(viewportWidth: number, groove: Groove) {
  if (viewportWidth >= 640) {
    return 0.8;
  }

  if (viewportWidth >= 540) {
    return 0.75;
  }

  const subdivisionCount = groove.bars.reduce(
    (sum, bar) => sum + bar.beats.reduce((sum, beat) => sum + beat.length, 0),
    0
  );

  return subdivisionCount <= 16 ? 0.75 : subdivisionCount <= 24 ? 0.65 : 0.5;
}

export function initializeSoundFont(soundFontUrl: string) {
  const sequence = new abcjs.synth.SynthSequence();
  sequence.addTrack();
  sequence.setInstrument(0, 128);
  voices
    .flatMap((v) => [
      v.pitch,
      ...Object.values(v.variants)
        .map((v) => v.pitch)
        .filter((p) => p != null),
    ])
    .forEach((pitch) => {
      sequence.appendNote(0, pitch!, 0.125, 0, 0);
    });

  const buffer = new abcjs.synth.CreateSynth();
  buffer.init({
    sequence: sequence as any,
    options: {
      soundFontUrl,
    },
  });
}

export function getSequenceCallback(groove: Groove): abcjs.SynthOptions["sequenceCallback"] {
  return (sequence) => {
    if (!groove) return sequence;

    console.debug(
      "sequenceCallback()",
      groove.name,
      groove.bars
        .flatMap((b) => b.beats)
        .flat()
        .flatMap((s) => s.notes)
        .map((n) => n.voiceId)
        .join(" ")
    );

    const subdivisionsFlattened = groove.bars
      .flatMap((b) => b.beats)
      .flat()
      .filter((s) => s.notes.length > 0);

    sequence.forEach((track) => {
      if (track.length === 0) return;

      let subIdx = 0;
      let position = track.find((item) => item.style !== "grace")?.start || 0;

      track.forEach((item, i) => {
        if (item.style === "grace") {
          // adjust all notes in subdivision immediatley after this grace note to have the position of this grace note
          // (the grace note will be shifted slightly before those notes)
          let nextIdx = i + 1;
          const start = track[nextIdx].start;

          while (track[nextIdx] && track[nextIdx].start === start) {
            track[nextIdx].start = item.start;
            nextIdx++;
          }

          item.volume = 20;
          item.start -= groove.bpm * 0.000125;
          return;
        }

        if (item.start > position) {
          position = item.start;
          subIdx += 1;
        }

        const voice = getVoice(item.pitch);
        if (!voice) {
          console.error(`[seq] failed to find voice for pitch: ${item.pitch}`);
          return;
        }

        const note = subdivisionsFlattened[subIdx].notes.find((n) => n.voiceId === voice.id);
        if (!note) {
          console.warn(
            `[seq] failed to find note for voice: ${voice.id} i: ${i} subIdx: ${subIdx}`
          );
          return;
        }

        const variantOptions = note.variantId ? voice.variants[note.variantId] : null;

        if (variantOptions) {
          if (variantOptions.volume != null) {
            item.volume = variantOptions.volume;
          }

          if (variantOptions.pitch != null) {
            item.pitch = variantOptions.pitch;
          }
        }

        // console.debug(
        //   `i: ${i} subIdx: ${subIdx} pos: ${item.start} pitch: ${item.pitch} [${
        //     abcjs.synth.pitchToNoteName[item.pitch]
        //   }] voice: ${voice.id} ${note.variantId ? `[${note.variantId}]` : ""}`
        // );
      });
    });

    return sequence;
  };
}

export function getBpm(tune: abcjs.TuneObject) {
  return Math.round((tune.getBeatsPerMeasure() / tune.millisecondsPerMeasure()) * 60_000);
}

function tripletToAbc(
  triplet: ReturnType<typeof calcTriplet>,
  topAnnotationHeight: number
): string {
  return triplet
    .map((data) => {
      const length = data.length;
      const abcLength = length > 1 ? length : length === 1 ? "" : "/".repeat(0.5 / length);

      // create tuplet
      if ("subs" in data) {
        return `(${data.subs.length}:${data.subs.length / 1.5}:${data.subs.length} ${data.subs
          .map((sub) => {
            return `${getAbcNote(sub, topAnnotationHeight)}${abcLength}`;
          })
          .join("")}`;
      }

      return `${getAbcNote(data.sub, topAnnotationHeight)}${abcLength}`;
    })
    .join("");
}

export function calcTriplet(beat: Beat, timeSignatureBottom = 4) {
  return _calcTriplet(beat, beat, timeSignatureBottom);
}

// recursively computes triplet subdivision note lengths
function _calcTriplet(
  beat: Beat,
  subs: BeatSubdivision[],
  timeSignatureBottom: number
): ({ sub: BeatSubdivision; length: number } | { subs: BeatSubdivision[]; length: number })[] {
  const subdivision = getSubdivision(timeSignatureBottom, beat);
  const fraction = subs.length / beat.length;

  // check if entire triplet beat can be consolidated into half-time (eg. x-x-x- => xxx)
  if (isHalfTime(beat)) {
    const halftimeBeat = beat.filter((_s, idx) => idx % 2 === 0);
    return _calcTriplet(halftimeBeat, halftimeBeat, timeSignatureBottom);
  }

  // check if entire triplet is single note
  if (isSingleNote(subs)) {
    return [
      {
        sub: subs[0],
        length: (ABC_SUBDIVISION / timeSignatureBottom) * fraction,
      },
    ];
  }

  if (subs.length % 2 === 0) {
    // length of 1/2 a beat adjusted proportionally for `subs` length relative to `beat` length
    const length = (ABC_SUBDIVISION / timeSignatureBottom / 2) * fraction;

    // check if first half of triplet is single note
    if (isSingleNote(subs.slice(0, subs.length / 2))) {
      return [
        { sub: subs[0], length },
        ..._calcTriplet(beat, subs.slice(subs.length / 2), timeSignatureBottom),
      ];
    }

    // check if second half of triplet is single note
    if (isSingleNote(subs.slice(subs.length / 2))) {
      return [
        ..._calcTriplet(beat, subs.slice(0, subs.length / 2), timeSignatureBottom),
        { sub: subs[subs.length / 2], length },
      ];
    }
  }

  // check if we need to split the triplet up due to ABC tuplet size constraints (max tuplet is 9)
  if (subs.length > 6) {
    return chunk(subs, 6)
      .map((subsChunk) => _calcTriplet(beat, subsChunk, timeSignatureBottom))
      .flat();
  }

  // full triplet
  const length = ABC_SUBDIVISION / (subdivision / 1.5);
  return [{ subs, length }];
}

function isSingleNote(subs: BeatSubdivision[]) {
  return (
    subs.length > 0 && subs[0].notes.length > 0 && subs.slice(1).every((s) => s.notes.length === 0)
  );
}

function isHalfTime(subs: BeatSubdivision[]) {
  return (
    subs.length > 0 &&
    subs.length % 2 === 0 &&
    subs.every((s, idx) => (idx % 2 === 0 ? s.notes.length >= 0 : s.notes.length === 0))
  );
}
