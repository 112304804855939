import { type Sticking } from "@/lib/groove";

export type Variant = {
  id: string;
  name: string;
  icon: string;
  suffix: string;
  position: "top" | "note";
};

export const variants: Variant[] = [
  { id: "ghost", name: "Ghost", icon: "⟮o⟯", suffix: "g", position: "note" },
  { id: "accent", name: "Accent", icon: ">", suffix: "a", position: "top" },
  { id: "open", name: "Open", icon: "○", suffix: "o", position: "top" },
  { id: "bell", name: "Bell", icon: "△", suffix: "b", position: "note" },
  { id: "flam", name: "Flam", icon: "♪", suffix: "f", position: "note" },
  { id: "buzz", name: "Buzz", icon: "☰", suffix: "z", position: "note" },
  {
    id: "xstick",
    name: "Cross stick",
    icon: "x",
    suffix: "x",
    position: "note",
  },
];

type VariantOptions = {
  volume?: number;
  pitch?: number;
  abcNote?: string;
  file?: string;
};

export type Voice = {
  id: string;
  name: string;
  abcNote: string;
  variants: Record<Variant["id"], VariantOptions>;
  pitch: number;
  file: string;
  // ordered based on frequency
  stickings: Sticking[];
  extra?: boolean;
};

export const voices: Voice[] = [
  {
    id: "c",
    name: "Crash",
    abcNote: "a",
    variants: {},
    pitch: 49,
    file: "Db3",
    stickings: ["R", "L"],
    extra: true,
  },
  {
    id: "r",
    name: "Ride",
    abcNote: "^f",
    variants: {
      accent: { volume: 120 },
      bell: { abcNote: "^e", pitch: 56, file: "Ab3" },
    },
    pitch: 51,
    file: "Eb3",
    stickings: ["R"],
    extra: true,
  },
  {
    id: "b",
    name: "Cowbell",
    abcNote: "^a",
    variants: {
      accent: { volume: 120 },
    },
    pitch: 57,
    file: "A3",
    stickings: ["R"],
    extra: true,
  },
  {
    id: "h",
    name: "Hi-Hat",
    abcNote: "g",
    variants: {
      accent: { volume: 120, pitch: 103, file: "G7" },
      open: { abcNote: "^g", pitch: 46, file: "Bb2" },
    },
    pitch: 42,
    stickings: ["R", "L"],
    file: "Gb2",
  },
  {
    id: "t1",
    name: "Tom 1",
    abcNote: "e",
    variants: {
      accent: { volume: 120 },
    },
    pitch: 48,
    stickings: ["R", "L"],
    file: "C3",
  },
  {
    id: "t2",
    name: "Tom 2",
    abcNote: "d",
    variants: {
      accent: { volume: 120 },
    },
    pitch: 47,
    stickings: ["R", "L"],
    file: "B2",
    extra: true,
  },
  {
    id: "s",
    name: "Snare",
    abcNote: "c",
    variants: {
      ghost: { volume: 80, pitch: 100, file: "E7" },
      accent: { volume: 120, pitch: 101, file: "F7" },
      buzz: { pitch: 102, file: "Gb7" },
      xstick: { abcNote: "=c", pitch: 37, file: "Db2" },
      flam: {},
    },
    pitch: 38,
    stickings: ["L", "R"],
    file: "D2",
  },
  {
    id: "t3",
    name: "Floor Tom",
    abcNote: "A",
    variants: {
      accent: { volume: 120 },
    },
    pitch: 45,
    stickings: ["R", "L"],
    file: "A2",
  },
  {
    id: "k",
    name: "Kick",
    abcNote: "F",
    variants: {
      accent: { volume: 120 },
    },
    pitch: 35,
    stickings: ["K"],
    file: "B1",
  },
  {
    id: "hh",
    name: "Hi-Hat Foot",
    abcNote: "D",
    variants: {},
    pitch: 44,
    file: "Ab2",
    stickings: ["H"],
    extra: true,
  },
];

export function getVoice(pitch: number): Voice | undefined {
  return voices.find(
    (v) =>
      v.pitch === pitch || (v.variants && Object.values(v.variants).some((v) => v.pitch === pitch))
  );
}
