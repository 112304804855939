import { unique } from "@/lib/utils";
import { type Voice, type Variant, variants, voices } from "@/lib/voice";

export type Subdivision = 8 | 12 | 16 | 24 | 32 | 48;
export const subdivisions: Subdivision[] = [8, 12, 16, 24, 32, 48];

export function getSubdivision(timeSignatureBottom: number, beat: Beat): Subdivision {
  return (timeSignatureBottom * beat.length) as Subdivision;
}

const subdivisionCounts: Record<Subdivision, string[]> = {
  8: ["1", "&"],
  12: ["1", "", ""],
  16: ["1", "e", "&", "a"],
  24: ["1", "", "", "&", "", ""],
  32: ["1", "", "e", "", "&", "", "a", ""],
  48: ["1", "", "", "e", "", "", "&", "", "", "a", "", ""],
};

export function getSubdivisionCounter(i: number, subdivision: Subdivision) {
  return subdivisionCounts[subdivision][i];
}

export function subdivisionName(subdivision: Subdivision) {
  switch (subdivision) {
    case 8:
      return "8th";
    case 12:
      return "8th triplet";
    case 16:
      return "16th";
    case 24:
      return "16th triplet";
    case 32:
      return "32nd";
    case 48:
      return "32nd triplet";
    default:
      return `X[${subdivision}]`;
  }
}

export function changeSubdivision(
  beat: Beat,
  timeSignatureBottom: number,
  newSubdivision: Subdivision
): Beat {
  const subdivision = getSubdivision(timeSignatureBottom, beat);
  if (subdivision === newSubdivision) {
    return beat;
  }

  // initialize new beat
  const newBeat = Array<BeatSubdivision>(newSubdivision / timeSignatureBottom).fill({
    notes: [],
    sticking: null,
  });

  // perform "smart" conversion of beat subdivision by matching notes in old <> new subdivisions
  // via their absolute position within the beat.
  //
  // ex. A measure of 16ths "x-x-" would have notes at 0 and 0.5, which would
  // fill in the new beat of say 16th triplets at "x--x--".
  return newBeat.map((sub, idx) => {
    const newNotePos = idx / (newSubdivision / timeSignatureBottom);

    const match = beat.find((_sub, idx) => {
      const notePos = idx / (subdivision / timeSignatureBottom);
      return notePos === newNotePos;
    });

    return match || sub;
  });
}

export type Note = {
  voiceId: Voice["id"];
  variantId: Variant["id"] | null | undefined;
};

export type Sticking = "R" | "L" | "K" | "RL" | "H";
// ordered based on frequency
export const stickings: Sticking[] = ["R", "L", "K", "RL", "H"];

export type StickingPreset = "all_R" | "all_L" | "all_off" | "alternate";

export const stickingPresets: [preset: StickingPreset, name: string][] = [
  ["all_R", "All R"],
  ["all_L", "All L"],
  ["alternate", "Alternate (R/L)"],
  ["all_off", "Off"],
];

export function stickingName(sticking: Sticking): string {
  switch (sticking) {
    case "RL":
      return "R/L";
    default:
      return sticking;
  }
}

export function getDefaultSticking(notes: Note[]): Sticking {
  const voiceStickings = unique(
    voices.filter((v) => notes.some((n) => n.voiceId === v.id)).flatMap((v) => v.stickings)
  );

  return voiceStickings[0] || stickings[0];
}

export type BeatSubdivision = {
  notes: Note[];
  sticking: Sticking | null;
};

export type Beat = BeatSubdivision[];

type Bar = {
  beats: Beat[];
};

export type Groove = {
  id: string | null;
  name: string;
  bpm: number;
  timeSignatureTop: number;
  timeSignatureBottom: number;
  bars: Bar[];
  notation: string; // used for groove comparison only (cheaper than converting `bars` back to `notation` every time)
  position: number | null;
};

/**
 * Maps Rails `Groove` model to JS representation
 */
export function toGroove(model: Record<string, any>, maxBars = Infinity): Groove {
  return {
    id: model.id,
    name: model.name,
    bpm: model.bpm,
    timeSignatureTop: model.time_signature_top,
    timeSignatureBottom: model.time_signature_bottom,
    bars: fromNotation(model.notation).slice(0, maxBars),
    notation: model.notation,
    position: model.position,
  };
}

const separators = {
  BAR: "|",
  BEAT: "_",
  SUBDIVISION: "-",
  NOTE: ",",
  STICKING: "~",
  VARIANT: ".",
};

export function toNotation(bars: Groove["bars"]): string {
  return bars
    .map((bar) =>
      bar.beats
        .map((beat) =>
          beat
            .map((sub) => {
              const notes = sub.notes
                .map((note) => {
                  const variant = variants.find((v) => v.id === note.variantId);
                  return [note.voiceId, variant?.suffix].filter(Boolean).join(separators.VARIANT);
                })
                .join(separators.NOTE);

              return [notes, sub.sticking].filter(Boolean).join(separators.STICKING);
            })
            .join(separators.SUBDIVISION)
        )
        .join(separators.BEAT)
    )
    .join(separators.BAR);
}

export function fromNotation(notation: string): Groove["bars"] {
  if (!notation) return [];

  return notation.split(separators.BAR).map((bar) => ({
    beats: bar.split(separators.BEAT).map((beat) =>
      beat.split(separators.SUBDIVISION).map((sub) => {
        const [notes, sticking] = sub.split(separators.STICKING);

        return {
          notes: notes
            .split(separators.NOTE)
            .map((note) => note.trim())
            .filter(Boolean)
            .map((note) => {
              const [voiceId, variantSuffix] = note.split(separators.VARIANT);
              return {
                voiceId,
                variantId: variants.find((v) => v.suffix === variantSuffix)?.id,
              };
            }),
          sticking: stickings.includes(sticking as any) ? (sticking as Sticking) : null,
        };
      })
    ),
  }));
}

export function isSameGroove(a: Groove, b: Groove) {
  // if either groove has an ID, then use that
  if (a.id != null || b.id != null) {
    return a.id === b.id;
  }

  // both grooves are un-persisted new objects so best effort compare
  return a.name === b.name && a.notation === b.notation;
}

/** helper function for determining `groove.id` for equality checks */
export function grooveId(groove: Pick<Groove, "id" | "name" | "notation">): string {
  return groove.id || `NEW[${groove.name}][${groove.notation}]`;
}
