import { type Updater } from "use-immer";
import { type ChartSection, type ChartData } from "@/lib/chart";
import { range } from "@/lib/utils";

export type ChartHooks = ReturnType<typeof useChartEditor>;

export function useChartEditor(
  initialChart: ChartData,
  onChange: Updater<ChartData>,
  liveMode = false
) {
  return {
    addLine() {
      onChange((draft) => {
        draft.lines.push({
          sections: range(4).map(() => ({ form: "", bars: "", notes: "" })),
        });
      });
    },

    deleteLine(lineIdx: number) {
      onChange((draft) => {
        draft.lines.splice(lineIdx, 1);
      });
    },

    moveLine(fromIdx: number, toIdx: number) {
      onChange((draft) => {
        const [line] = draft.lines.splice(fromIdx, 1);
        draft.lines.splice(toIdx, 0, line);
      });
    },

    revertLine(lineIdx: number) {
      if (!initialChart.lines[lineIdx]) return;

      onChange((draft) => {
        draft.lines[lineIdx] = initialChart.lines[lineIdx];
      });
    },

    addSection(lineIdx: number) {
      onChange((draft) => {
        draft.lines[lineIdx].sections.push({
          form: "",
          bars: liveMode ? "1" : "",
          notes: "",
        });
      });
    },

    deleteSection(lineIdx: number, sectionIdx: number) {
      onChange((draft) => {
        draft.lines[lineIdx].sections.splice(sectionIdx, 1);
      });
    },

    moveSection(
      from: { lineIdx: number; sectionIdx: number },
      to: { lineIdx: number; sectionIdx: number }
    ) {
      onChange((draft) => {
        const [section] = draft.lines[from.lineIdx].sections.splice(
          from.sectionIdx,
          1
        );
        draft.lines[to.lineIdx].sections.splice(to.sectionIdx, 0, section);
      });
    },

    updateSection(
      lineIdx: number,
      sectionIdx: number,
      data: Partial<ChartSection>
    ) {
      onChange((draft) => {
        draft.lines[lineIdx].sections[sectionIdx] = {
          ...draft.lines[lineIdx].sections[sectionIdx],
          ...data,
        };
      });
    },
  };
}
