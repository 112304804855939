import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function pad<T>(a: T[], length: number, fill: T, position: "start" | "end" = "end"): T[] {
  if (a.length >= length) {
    return a; // No need to pad
  }

  const padding = new Array(length - a.length).fill(fill);

  if (position === "start") {
    return [...padding, ...a];
  } else {
    return [...a, ...padding];
  }
}

export function sumBy<T>(items: T[], fn: (item: T) => number): number {
  return items.reduce((sum, item) => sum + fn(item), 0);
}

export function debounce<T extends (...args: Parameters<T>) => any>(
  fn: T,
  delay = 250
): (...args: Parameters<T>) => void {
  let timer;
  return (...args: Parameters<T>) => {
    clearTimeout(timer);
    timer = setTimeout(() => fn.apply(this, args), delay);
  };
}

export function unique<T>(items: T[]): T[] {
  return [...new Set(items)];
}

export function range(length: number): number[];
export function range(start: number, end: number): number[];
export function range(startOrLength: number, end?: number) {
  const start = Number.isInteger(end) ? startOrLength : 0;
  end = Number.isInteger(end) ? end : startOrLength;

  return Array.from<number, number>({ length: end! - start }, (_, idx) => start + idx);
}

export function chunk<T>(a: T[], size: number): T[][] {
  return Array.from({ length: Math.ceil(a.length / size) }, (_, idx) =>
    a.slice(idx * size, idx * size + size)
  );
}
