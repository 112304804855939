import React from "react";
import { createRoot } from "react-dom/client";
import ahoy from "ahoy.js";
import abcjs from "abcjs";
import { type Groove, toNotation, toGroove, isSameGroove, grooveId } from "@/lib/groove";
import {
  type GrooveRenderMode,
  getBpm,
  getSequenceCallback,
  initializeSoundFont,
  renderGroove,
  grooveToAbcNotes,
} from "@/lib/abc";
import { debounce } from "@/lib/utils";
import GrooveEditor from "@/components/GrooveEditor";

type GrooveWithTune = Groove & { tune: abcjs.TuneObject };

export const grooveMagic =
  () =>
  (...args: Parameters<typeof toGroove>): Groove => {
    return Object.freeze(toGroove(...args));
  };

const synth = new abcjs.synth.SynthController();

export const grooveSynthDirective = (el, {}, { Alpine, cleanup }) => {
  synth.load(
    el,
    {
      onEvent(e) {
        // clear highlights
        document.querySelectorAll(".abcjs-highlight").forEach((el) => {
          el.classList.remove("abcjs-highlight");
        });

        // highlight selections
        e.elements?.forEach((el) => {
          (Array.isArray(el) ? el : [el]).forEach((el) => {
            el.classList.add("abcjs-highlight");
          });
        });
      },
      onFinished() {
        // clear highlights
        document.querySelectorAll(".abcjs-highlight").forEach((el) => {
          el.classList.remove("abcjs-highlight");
        });
      },
    },
    {}
  );
  synth.disable(false);

  cleanup(() => {
    synth.pause();
    synth.disable(true);
    Alpine.store("groovePlayer").pause();
  });
};

type GroovePlayerStore = {
  playing: boolean;
  groove: GrooveWithTune | null;
  loop: boolean;
  synth: typeof synth;
  soundFontUrl: string;

  init(this: GroovePlayerStore): void;
  play(this: GroovePlayerStore, groove?: GrooveWithTune): void;
  pause(this: GroovePlayerStore): void;
  toggle(this: GroovePlayerStore, groove?: GrooveWithTune): void;
  toggleLoop(this: GroovePlayerStore): void;
  isPlaying(this: GroovePlayerStore, groove?: GrooveWithTune): boolean;
  update(this: GroovePlayerStore, groove: GrooveWithTune): void;
  _setGroove(this: GroovePlayerStore, groove: GrooveWithTune): void;
};

export function groovePlayerStore(Alpine): GroovePlayerStore {
  return {
    playing: false,
    groove: null,
    loop: Alpine.$persist(true).as("groovePlayer_loop"),
    synth,
    soundFontUrl: "/sounds",

    init() {
      initializeSoundFont(this.soundFontUrl);
    },

    async play(groove?: GrooveWithTune) {
      if (groove) {
        this._setGroove(groove);
      }

      if (!this.groove) {
        console.error(`groove not set!`);
        return;
      }

      if (this.groove.id) {
        ahoy.track("groove.played", { groove_id: this.groove.id });
      }

      console.debug(
        "play()",
        this.groove.name,
        this.groove.bars
          .flatMap((b) => b.beats)
          .flat()
          .flatMap((s) => s.notes)
          .map((n) => n.voiceId)
          .join(" ")
      );

      await this.synth
        .setTune(this.groove.tune, true, {
          soundFontUrl: this.soundFontUrl,
          fadeLength: 500,
          onEnded: () => {
            if (!this.loop) {
              this.playing = false;
            }
          },
          sequenceCallback: getSequenceCallback(this.groove),
        })
        .then(() => {
          if (this.loop) {
            this.synth.toggleLoop();
          }
        });

      this.synth.play();
      this.playing = true;
    },

    pause() {
      this.synth.pause();
      this.playing = false;
    },

    toggle(groove?: GrooveWithTune) {
      if (this.isPlaying(groove)) {
        this.pause();
      } else {
        this.play(groove);
      }
    },

    toggleLoop() {
      this.synth.toggleLoop();
      this.loop = !this.loop;
    },

    isPlaying(groove?: GrooveWithTune): boolean {
      return this.playing && !!this.groove && (!groove || isSameGroove(groove, this.groove));
    },

    update(groove: GrooveWithTune) {
      if (!this.isPlaying()) {
        this._setGroove(groove);
      } else {
        this.pause();
        this.play(groove);
      }
    },

    _setGroove(groove: GrooveWithTune) {
      this.groove = groove;
    },
  };
}

export const grooveComponent = (groove: Groove, mode: GrooveRenderMode = "view") => ({
  tune: null,

  tuneContainer: {
    ["x-init"]() {
      this.tune = renderGroove(this.$el, groove, mode);
    },

    ["x-resize.width"]() {
      this.tune = renderGroove(this.$el, { ...groove, bpm: getBpm(this.tune) }, mode);

      if (this.isPlaying) {
        this.$store.groovePlayer.update({ ...groove, tune: this.tune });
      }
    },

    ["@bpm-changed.window"]() {
      if (!isSameGroove(this.$event.detail.groove, groove)) return;
      if (this.$event.detail.bpm === getBpm(this.tune)) return;

      this.tune = renderGroove(this.$el, { ...groove, bpm: this.$event.detail.bpm }, mode);

      if (this.$store.groovePlayer.groove?.id === groove.id) {
        this.$store.groovePlayer.update({ ...groove, tune: this.tune });
      }
    },
  },

  play() {
    this.$store.groovePlayer.play({ ...groove, tune: this.tune });
  },

  toggle() {
    this.$store.groovePlayer.toggle({ ...groove, tune: this.tune });
  },

  get isPlaying() {
    return this.$store.groovePlayer.isPlaying({ ...groove, tune: this.tune });
  },

  init() {},
});

export const groovePlayerComponent = () => ({
  bpm: null,

  init() {
    this.resetBpm();

    this.$watch("grooveId", (grooveId: string, oldGrooveId?: string) => {
      if (grooveId === oldGrooveId) return;
      this.bpm = getBpm(this.groove.tune);
    });

    const debounced = debounce((detail: any) => {
      this.$dispatch("bpm-changed", detail);
    }, 500);

    this.$watch("bpm", (bpm: number, oldBpm: number | null) => {
      if (oldBpm == null) return;
      debounced({ groove: this.groove, bpm });
    });
  },

  resetBpm() {
    if (this.groove) this.bpm = this.groove.bpm;
  },

  get groove() {
    return this.$store.groovePlayer.groove;
  },

  get grooveId() {
    return this.$store.groovePlayer.groove ? grooveId(this.$store.groovePlayer.groove) : null;
  },

  get isPlaying() {
    return this.$store.groovePlayer.isPlaying();
  },

  toggle() {
    this.$store.groovePlayer.toggle();
  },

  get isLooping() {
    return this.$store.groovePlayer.loop;
  },

  toggleLoop() {
    this.$store.groovePlayer.toggleLoop();
  },
});

export const grooveEditorComponent = (groove: Groove, devTools = false) => ({
  groove,
  tune: null,
  destroy: false,

  init() {},

  toggle() {
    this.$store.groovePlayer.toggle({ ...this.groove, tune: this.tune });
  },

  get isPlaying() {
    return this.$store.groovePlayer.isPlaying({
      ...this.groove,
      tune: this.tune,
    });
  },

  get notation() {
    return toNotation(this.groove.bars);
  },

  get abc() {
    return grooveToAbcNotes(this.groove);
  },

  grooveDisplay: {
    ["x-init"]() {
      this.tune = renderGroove(this.$el, groove, "edit");

      const debounced = debounce((updatedGroove: Groove) => {
        if (this.$store.groovePlayer.groove?.id !== updatedGroove.id) return;

        this.$store.groovePlayer.update({
          ...updatedGroove,
          tune: this.tune,
        });
      }, 500);

      this.$watch("groove", (updatedGroove: Groove) => {
        this.tune = renderGroove(this.$el, updatedGroove, "edit");
        debounced(updatedGroove);
      });
    },

    ["x-resize.width"]() {
      this.tune = renderGroove(this.$el, { ...this.groove, bpm: getBpm(this.tune) }, "edit");

      if (this.isPlaying) {
        this.$store.groovePlayer.update({ ...this.groove, tune: this.tune });
      }
    },

    ["@bpm-changed.window"]() {
      if (!isSameGroove(this.$event.detail.groove, this.groove)) return;
      if (this.$event.detail.bpm === getBpm(this.tune)) return;

      this.tune = renderGroove(this.$el, { ...this.groove, bpm: this.$event.detail.bpm }, "edit");

      if (this.$store.groovePlayer.groove?.id === this.groove.id) {
        this.$store.groovePlayer.update({ ...this.groove, tune: this.tune });
      }
    },
  },

  grooveEditor: {
    ["x-init"]() {
      const root = createRoot(this.$el);
      root.render(
        React.createElement(GrooveEditor, {
          groove,
          onChange: (groove) => {
            this.groove = groove;
          },
          devTools,
        })
      );
    },
  },
});
