import { Fragment, useEffect, useState } from "react";
import { useImmer } from "use-immer";
import { MusicalNoteIcon } from "@heroicons/react/24/outline";
import {
  PlusIcon,
  MinusIcon,
  TrashIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
  ChevronUpIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/outline";
import { type ChartSection, type ChartData } from "@/lib/chart";
import { cn } from "@/lib/utils";
import { useChartEditor } from "@/lib/chart-hooks";
import { Switch } from "@/components/ui/switch";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

export default function ChartEditor({
  chart: initialChart,
  onChange,
}: {
  chart: ChartData;
  onChange(chart: ChartData): void;
}) {
  const [liveMode, setLiveMode] = useState(true);
  const [chart, setChart] = useImmer(initialChart);

  const {
    addLine,
    moveLine,
    deleteLine,
    addSection,
    moveSection,
    deleteSection,
    updateSection,
  } = useChartEditor(initialChart, setChart, liveMode);

  useEffect(() => onChange(chart), [chart]);

  return (
    <div className="space-y-6">
      <div className="flex flex-row items-center justify-between rounded-lg border border-slate-200 px-4 py-3">
        <div className="space-y-0.5">
          <label
            htmlFor="liveMode"
            className="text-base font-medium text-slate-900"
          >
            Listening mode
          </label>
          <p className="text-sm text-slate-600">
            Optimized for creating chart as you listen to the song.
          </p>
        </div>
        <Switch
          id="liveMode"
          checked={liveMode}
          onCheckedChange={(checked) => setLiveMode(checked)}
        />
      </div>

      <div className="pb-2 overflow-x-auto">
        <table className="table-auto border-collapse">
          <tbody>
            {chart.lines.map((line, lineIdx) => {
              return (
                <Fragment key={lineIdx}>
                  {/* content */}
                  <tr>
                    <th className="hidden sm:table-cell" />
                    {line.sections.map((section, sectionIdx) => (
                      <td
                        key={sectionIdx}
                        className="p-2 text-center min-w-[80px] max-w-[300px]"
                      >
                        <div className="w-full flex justify-center">
                          <div className="w-fit flex items-center text-slate-500">
                            <button
                              tabIndex={-1}
                              type="button"
                              className="p-1 inline-flex items-center justify-center whitespace-nowrap bg-transparent rounded-sm text-sm font-medium hover:bg-slate-50 hover:text-slate-900 disabled:pointer-events-none disabled:opacity-50"
                              disabled={lineIdx === 0 && sectionIdx === 0}
                              onClick={() => {
                                if (sectionIdx === 0) {
                                  moveSection(
                                    { lineIdx, sectionIdx },
                                    {
                                      lineIdx: lineIdx - 1,
                                      sectionIdx:
                                        chart.lines[lineIdx - 1].sections
                                          .length,
                                    }
                                  );
                                } else {
                                  moveSection(
                                    { lineIdx, sectionIdx },
                                    { lineIdx, sectionIdx: sectionIdx - 1 }
                                  );
                                }
                              }}
                            >
                              <ChevronLeftIcon className="h-4 w-4" />
                            </button>
                            <button
                              tabIndex={-1}
                              type="button"
                              className="p-1 inline-flex items-center justify-center whitespace-nowrap bg-transparent rounded-sm text-sm font-medium hover:bg-slate-50 hover:text-slate-900 disabled:pointer-events-none disabled:opacity-50"
                              onClick={() => {
                                if (
                                  canAutoDeleteSection(section) ||
                                  confirm("Are you sure?")
                                ) {
                                  deleteSection(lineIdx, sectionIdx);
                                }
                              }}
                            >
                              <TrashIcon className="h-4 w-4" />
                            </button>
                            <button
                              tabIndex={-1}
                              type="button"
                              className="p-1 inline-flex items-center justify-center whitespace-nowrap bg-transparent rounded-sm text-sm font-medium hover:bg-slate-50 hover:text-slate-900 disabled:pointer-events-none disabled:opacity-50"
                              disabled={
                                lineIdx === chart.lines.length - 1 &&
                                sectionIdx === line.sections.length - 1
                              }
                              onClick={() => {
                                if (sectionIdx === line.sections.length - 1) {
                                  moveSection(
                                    { lineIdx, sectionIdx },
                                    { lineIdx: lineIdx + 1, sectionIdx: 0 }
                                  );
                                } else {
                                  moveSection(
                                    { lineIdx, sectionIdx },
                                    { lineIdx, sectionIdx: sectionIdx + 1 }
                                  );
                                }
                              }}
                            >
                              <ChevronRightIcon className="h-4 w-4" />
                            </button>
                          </div>
                        </div>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <ChartHeader name="Form" className="border-r border-b" />
                    {line.sections.map((section, sectionIdx) => (
                      <ChartTextField
                        key={sectionIdx}
                        className="border-l border-b"
                        value={section.form}
                        onChange={(form) =>
                          updateSection(lineIdx, sectionIdx, { form })
                        }
                      />
                    ))}
                    <td rowSpan={3}>
                      <div className="ml-4 w-fit flex flex-col items-center text-slate-500">
                        <button
                          tabIndex={-1}
                          type="button"
                          className="p-1.5 inline-flex items-center justify-center whitespace-nowrap bg-transparent rounded-sm text-sm font-medium hover:bg-slate-50 hover:text-slate-900 disabled:pointer-events-none disabled:opacity-50"
                          onClick={() => moveLine(lineIdx, lineIdx - 1)}
                          disabled={lineIdx === 0}
                        >
                          <ChevronUpIcon className="h-4 w-4" />
                        </button>
                        <button
                          tabIndex={-1}
                          type="button"
                          className="p-1.5 inline-flex items-center justify-center whitespace-nowrap bg-transparent rounded-sm text-sm font-medium hover:bg-slate-50 hover:text-slate-900 disabled:pointer-events-none disabled:opacity-50"
                          onClick={() => addSection(lineIdx)}
                        >
                          <PlusIcon className="h-4 w-4" />
                        </button>
                        <button
                          tabIndex={-1}
                          type="button"
                          className="p-1.5 inline-flex items-center justify-center whitespace-nowrap bg-transparent rounded-sm text-sm font-medium hover:bg-slate-50 hover:text-slate-900 disabled:pointer-events-none disabled:opacity-50"
                          onClick={() => {
                            if (
                              line.sections.every(canAutoDeleteSection) ||
                              confirm("Are you sure?")
                            ) {
                              deleteLine(lineIdx);
                            }
                          }}
                        >
                          <TrashIcon className="h-4 w-4" />
                        </button>
                        <button
                          tabIndex={-1}
                          type="button"
                          className="p-1.5 inline-flex items-center justify-center whitespace-nowrap bg-transparent rounded-sm text-sm font-medium hover:bg-slate-50 hover:text-slate-900 disabled:pointer-events-none disabled:opacity-50"
                          onClick={() => moveLine(lineIdx, lineIdx + 1)}
                          disabled={lineIdx === chart.lines.length - 1}
                        >
                          <ChevronDownIcon className="h-4 w-4" />
                        </button>
                      </div>
                      {/* <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                  <Button variant="outline" size="icon">
                                    <EllipsisHorizontalIcon className="h-4 w-4" />
                                  </Button>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent>
                                  <DropdownMenuItem
                                    onClick={() => {
                                      setChart((chart) => {
                                        chart.lines[lineIdx].sections.push({});
                                      });
                                    }}
                                  >
                                    <PlusIcon className="mr-2 h-4 w-4" />
                                    <span>Add</span>
                                  </DropdownMenuItem>
                                  <DropdownMenuItem
                                    disabled={
                                      chart.lines[lineIdx].sections.length === 0
                                    }
                                    onClick={() => {
                                      setChart((chart) => {
                                        chart.lines[lineIdx].sections[
                                          chart.lines[lineIdx].sections.length - 1
                                        ]._destroy = true;
                                      });
                                    }}
                                  >
                                    <MinusIcon className="mr-2 h-4 w-4" />
                                    <span>Remove</span>
                                  </DropdownMenuItem>
                                  <DropdownMenuItem
                                    onClick={() => {
                                      setChart((chart) => {
                                        chart.lines[lineIdx]._destroy = true;
                                      });
                                    }}
                                  >
                                    <TrashIcon className="mr-2 h-4 w-4" />
                                    <span>Delete all</span>
                                  </DropdownMenuItem>
                                </DropdownMenuContent>
                            </DropdownMenu> */}
                    </td>
                  </tr>
                  <tr>
                    <ChartHeader name="Bars" className="border-r border-y" />
                    {line.sections.map((section, sectionIdx) => {
                      const Field = liveMode
                        ? ChartNumberField
                        : ChartTextField;
                      return (
                        <Field
                          key={sectionIdx}
                          className="border-l border-y"
                          value={section.bars}
                          onChange={(bars) =>
                            updateSection(lineIdx, sectionIdx, { bars })
                          }
                        />
                      );
                    })}
                  </tr>
                  <tr>
                    <ChartHeader name="Notes" className="border-r border-t" />
                    {line.sections.map((section, sectionIdx) => (
                      <ChartTextField
                        key={sectionIdx}
                        className="border-l border-t"
                        value={section.notes}
                        onChange={(notes) =>
                          updateSection(lineIdx, sectionIdx, { notes })
                        }
                      />
                    ))}
                  </tr>
                  {lineIdx < chart.lines.length - 1 && (
                    <tr className="h-8 w-full" tabIndex={-1}>
                      <td />
                    </tr>
                  )}
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </div>

      <button
        type="button"
        className="group transition inline-flex flex-col items-center space-y-2 w-full rounded-md border-2 border-dotted border-slate-200 p-8 text-center hover:border-slate-300 focus:outline-none focus:ring-2 focus:ring-slate-200 focus:ring-offset-2"
        onClick={() => addLine()}
      >
        <MusicalNoteIcon className="h-8 w-8 text-slate-600 transition group-hover:text-[#FF54AD]" />
        <span className="text-slate-900">Add line</span>
      </button>
    </div>
  );
}

function ChartHeader({ name, className = "" }) {
  return (
    <th
      className={cn(
        "hidden sm:table-cell whitespace-nowrap px-4 py-3 text-left text-sm font-normal border-slate-200 text-slate-600 sm:pl-0",
        className
      )}
    >
      {name}
    </th>
  );
}

function ChartTextField({ value, onChange, className = "" }) {
  return (
    <td
      className={cn(
        "p-2 whitespace-pre text-center text-base text-slate-900 font-medium border-slate-200 min-w-[80px] max-w-[300px] focus:outline-none focus:bg-slate-50",
        className
      )}
      contentEditable="true"
      onBlur={(e) => onChange(e.currentTarget.innerText)}
      suppressContentEditableWarning
    >
      {value}
    </td>
  );
}

function ChartNumberField({ value, onChange, className = "" }) {
  const valueAsNumber = parseInt(value) || 0;
  return (
    <td
      className={cn(
        "p-2 whitespace-pre text-base text-slate-900 font-medium border-slate-200 min-w-[90px] max-w-[300px] focus:outline-none focus:bg-slate-50",
        className
      )}
    >
      <div className="w-full flex items-center justify-between">
        <button
          type="button"
          className="p-[3px] rounded border border-slate-200 text-slate-300 hover:bg-slate-50 focus:border-slate-300"
          onClick={() => onChange(Math.max(0, valueAsNumber - 1).toString())}
        >
          <MinusIcon className="h-4 w-4" />
        </button>
        <span className="px-0.5">{value}</span>
        <button
          type="button"
          className="p-[3px] rounded border border-slate-200 text-slate-300 hover:bg-slate-50 focus:border-slate-300"
          onClick={() => onChange((valueAsNumber + 1).toString())}
        >
          <PlusIcon className="h-4 w-4" />
        </button>
      </div>
    </td>
  );
}

function canAutoDeleteSection(section: ChartSection): boolean {
  return !section.form && (!section.bars || !section.notes);
}
