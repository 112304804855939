import React from "react";
import { createRoot } from "react-dom/client";
import { safeParse } from "valibot";
import ChartEditor from "@/components/ChartEditor";
import { ChartDataSchema, type ChartData } from "@/lib/chart";

export const chartDataMagic =
  () =>
  (input: unknown): ChartData => {
    const res = safeParse(ChartDataSchema, input);
    if (res.success) {
      return Object.freeze(res.output);
    }

    console.error("invalid chart data", input, res.issues);
    return Object.freeze({ lines: [] });
  };

export const chartEditorComponent = (chart: ChartData) => ({
  chart,

  get data() {
    return JSON.stringify(this.chart);
  },

  chartEditor: {
    ["x-init"]() {
      const root = createRoot(this.$el);
      root.render(
        React.createElement(ChartEditor, {
          chart,
          onChange: (chart) => {
            this.chart = chart;
          },
        })
      );
    },
  },
});
