import { fromNotation, type Groove } from "@/lib/groove";

export type VoicePreset = "all_on" | "all_off" | "downbeats" | "upbeats";

export const voicePresets: [preset: VoicePreset, name: string][] = [
  ["all_on", "All on"],
  ["all_off", "All off"],
  ["downbeats", "Downbeats"],
  ["upbeats", "Upbeats"],
];

export type GroovePreset = Pick<
  Groove,
  "name" | "timeSignatureTop" | "timeSignatureBottom" | "bars"
>;

export const groovePresets: {
  category: string;
  grooves: GroovePreset[];
}[] = [
  {
    category: "Rock",
    grooves: [
      {
        name: "Rock - 8th",
        timeSignatureTop: 4,
        timeSignatureBottom: 4,
        bars: fromNotation("k,h--h-_s,h--h-_h,k--h-_h,s--h-"),
      },
      {
        name: "Rock - 16th",
        timeSignatureTop: 4,
        timeSignatureBottom: 4,
        bars: fromNotation("k,h-h-h-h_s,h-h-h-h_h,k-h-h-h_h,s-h-h-h"),
      },
      {
        name: "Train Beat",
        timeSignatureTop: 4,
        timeSignatureBottom: 4,
        bars: fromNotation(
          "k,h,s.g-h,s.g-h,hh,s-h,s.g_s.g,h,k-h,s.g-h,hh,s-h,s.g_h,k,s.g-h,s.g-h,hh,s-h,s.g_h,s.g,k-h,s.g-h,hh,s-h,s.g"
        ),
      },
    ],
  },
  {
    category: "Chops",
    grooves: [
      {
        name: "6-stroke roll",
        timeSignatureTop: 4,
        timeSignatureBottom: 4,
        bars: fromNotation(
          "s.a~R-s.g~L-s.g~L-s.g~R-s.g~R-s.a~L_s.a~R-s.g~L-s.g~L-s.g~R-s.g~R-s.a~L_s.a~R-s.g~L-s.g~L-s.g~R-s.g~R-s.a~L_s.a~R-s.g~L-s.g~L-s.g~R-s.g~R-s.a~L"
        ),
      },
      {
        name: "RLRLLK",
        timeSignatureTop: 4,
        timeSignatureBottom: 4,
        bars: fromNotation(
          "h~R-s.g~L-h~R-s.g~L-s.g~L-k~K_h~R-s.g~L-h~R-s.g~L-s.g~L-k~K_h~R-s.g~L-h~R-s.g~L-s.g~L-k~K_h~R-s.g~L-h~R-s.g~L-s.g~L-k~K"
        ),
      },
      {
        name: "RLK",
        timeSignatureTop: 4,
        timeSignatureBottom: 4,
        bars: fromNotation(
          "s~R-t1~L-k~K-t3~R-t1~L-k~K_s~R-t1~L-k~K-t3~R-t1~L-k~K_s~R-t1~L-k~K-t3~R-t1~L-k~K_s~R-t1~L-k~K-t3~R-t1~L-k~K"
        ),
      },
    ],
  },
  {
    category: "Swing",
    grooves: [
      {
        name: "Jazz Ride",
        timeSignatureTop: 4,
        timeSignatureBottom: 4,
        bars: fromNotation("h--_h,hh--h_h--_h,hh--h"),
      },
      {
        name: "Purdie Shuffle",
        timeSignatureTop: 4,
        timeSignatureBottom: 4,
        bars: fromNotation("k,h-s.g-h_h-s.g-h,k_h,s.a-s.g-h_h-s.g-h,k"),
      },
      {
        name: "Texas Shuffle",
        timeSignatureTop: 4,
        timeSignatureBottom: 4,
        bars: fromNotation(
          "k,s.g,r--s.g,r_k,s.a,r--s.g,r_k,s.g,r--s.g,r_k,s.a,r--s.g,r"
        ),
      },
    ],
  },
  {
    category: "World",
    grooves: [
      {
        name: "Bossa Nova",
        timeSignatureTop: 4,
        timeSignatureBottom: 4,
        bars: fromNotation(
          "k,h,s.x-h_s.x,h,hh-h,k_h,k-h,s.x_h,hh-h,k,s.x|k,h-h,s.x_h,hh-h,k_h,k,s.x-h_h,hh,s.x-h,k"
        ),
      },
      {
        name: "Mozambique",
        timeSignatureTop: 4,
        timeSignatureBottom: 4,
        bars: fromNotation("k,s.a,b--b-s.a_b-b-s.g-b_s.g-b-b-s.a_b-b-s.g-b,k"),
      },
      {
        name: "Samba",
        timeSignatureTop: 4,
        timeSignatureBottom: 4,
        bars: fromNotation(
          "k,s,r--r,hh,s-r,k_r,k-s-r,hh-r,k,s_k,r-s-r,hh-r,k,s_s,r,k--r,hh,s-r,k"
        ),
      },
    ],
  },
];
