import React, { useEffect, useMemo, useState } from "react";
import cn from "clsx";
import { useImmer } from "use-immer";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import * as ContextMenu from "@radix-ui/react-context-menu";
import {
  ChevronUpIcon,
  ChevronDownIcon,
  ChevronUpDownIcon,
  PlusIcon,
  PlayCircleIcon,
  PauseCircleIcon,
} from "@heroicons/react/24/solid";
import {
  ChevronRightIcon,
  ChevronLeftIcon,
  DocumentDuplicateIcon,
  ArrowUturnLeftIcon,
  BackspaceIcon,
  TrashIcon,
} from "@heroicons/react/20/solid";
import { variants, voices } from "@/lib/voice";
import {
  type Groove,
  subdivisionName,
  subdivisions,
  getSubdivision,
  stickings,
  stickingName,
  getSubdivisionCounter,
  getDefaultSticking,
  stickingPresets,
} from "@/lib/groove";
import { type GrooveHooks, useGrooveEditor } from "@/lib/groove-hooks";
import { type GroovePreset, groovePresets, voicePresets } from "@/lib/presets";
import { range, unique } from "@/lib/utils";
import { Popover, PopoverTrigger, PopoverContent } from "@/components/ui/popover";
import { Command, CommandEmpty, CommandGroup, CommandItem } from "@/components/ui/command";
import { Button } from "@/components/ui/button";

export default function GrooveEditorContainer({
  groove: initialGroove,
  onChange,
  devTools = false,
}: {
  groove: Groove;
  onChange(groove: Groove): void;
  devTools?: boolean;
}) {
  const [groove, setGroove] = useImmer(initialGroove);
  const grooveHooks = useGrooveEditor(initialGroove, setGroove);

  useEffect(() => {
    onChange({ ...groove });
  }, [groove]);

  return (
    <div className="overflow-hidden rounded-md ring-1 ring-slate-200 divide-y divide-slate-200">
      <div className="px-3 py-2 flex items-center justify-between gap-2">
        <div className="flex items-center gap-3">
          <button
            type="button"
            className="text-slate-900 hover:text-slate-800"
            x-on:click="toggle()"
          >
            <PlayCircleIcon className="h-12 w-12" x-show="!isPlaying" />
            <PauseCircleIcon className="h-12 w-12" x-show="isPlaying" x-cloak="true" />
          </button>
          <GrooveBpmInput />
        </div>

        <div className="ml-auto flex w-full space-x-2 justify-end">
          <GroovePresetSelector
            onSelect={({ timeSignatureTop, timeSignatureBottom, bars }) => {
              setGroove({
                ...groove,
                timeSignatureTop,
                timeSignatureBottom,
                bars,
              });
            }}
          />
        </div>
      </div>

      <div className="min-h-[116px] flex flex-col justify-center gap-2 px-3 pt-1 pb-2">
        <div x-bind="grooveDisplay"></div>
        {devTools && <GrooveDevTools />}
      </div>

      <GrooveEditor groove={groove} grooveHooks={grooveHooks} />
    </div>
  );
}

function GrooveBpmInput() {
  return (
    <div className="w-[112px]">
      <label className="sr-only">Tempo</label>
      <div className="relative">
        <input
          type="number"
          inputMode="numeric"
          className="w-full text-sm font-medium text-slate-900 rounded-md border border-slate-200 outline-none pl-3 pr-12 py-1.5 focus:border-slate-200 focus:ring-2 focus:ring-slate-300"
          {...{ ["x-model.number"]: "groove.bpm" }}
        />
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <span className="text-sm text-slate-500">bpm</span>
        </div>
      </div>
    </div>
  );
}

function GroovePresetSelector({ onSelect }: { onSelect(preset: GroovePreset): void }) {
  const [open, setOpen] = useState(false);
  const [selectedPreset, setSelectedPreset] = useState<GroovePreset>();

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-label="Load preset..."
          aria-expanded={open}
          className="flex-1 justify-between font-normal max-w-[200px] lg:max-w-[250px]"
        >
          {selectedPreset ? selectedPreset.name : "Load preset..."}
          <ChevronUpDownIcon className="ml-1 -mr-1 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[250px] px-0 py-0.5" align="end">
        <Command>
          <CommandEmpty>No presets found.</CommandEmpty>
          {groovePresets.map(({ category, grooves }, idx) => (
            <CommandGroup key={idx} heading={category}>
              {grooves.map((groove, idx) => (
                <CommandItem
                  key={idx}
                  onSelect={() => {
                    onSelect(groove);
                    setSelectedPreset(groove);
                    setOpen(false);
                  }}
                  className={cn(selectedPreset?.name === groove.name && "font-medium")}
                >
                  {groove.name}
                </CommandItem>
              ))}
            </CommandGroup>
          ))}
        </Command>
      </PopoverContent>
    </Popover>
  );
}

function GrooveDevTools() {
  return (
    <div>
      {/* Drumlix notation */}
      <div className="relative flex items-center">
        <input
          x-bind:value="notation"
          type="text"
          readOnly
          className="block truncate font-mono text-xs border border-slate-200 outline-none pl-2 pr-10 py-1 w-full"
        />
        <div className="absolute inset-y-0 right-0 flex py-1 pr-1">
          <button
            x-on:click="$clipboard(notation)"
            title="Copy"
            type="button"
            className="px-1.5 py-1 rounded text-xs tracking-wide uppercase text-slate-600 hover:bg-slate-50 active:bg-slate-100"
          >
            Copy
          </button>
        </div>
      </div>

      {/* ABC notation */}
      <div className="relative flex items-center">
        <input
          x-bind:value="abc"
          type="text"
          readOnly
          className="block truncate font-mono text-xs border border-slate-200 outline-none pl-2 pr-10 py-1 w-full"
        />
        <div className="absolute inset-y-0 right-0 flex py-1 pr-1">
          <button
            x-on:click="$clipboard(abc)"
            title="Copy"
            type="button"
            className="px-1.5 py-1 rounded text-xs tracking-wide uppercase text-slate-600 hover:bg-slate-50 active:bg-slate-100"
          >
            Copy
          </button>
        </div>
      </div>
    </div>
  );
}

function GrooveEditor({
  groove,
  grooveHooks: {
    addBar,
    clearBar,
    deleteBar,
    revertBar,
    duplicateBar,
    moveBar,
    setNote,
    toggleNote,
    setSticking,
    toggleSticking,
    setGrooveSubdivision,
    setBeatSubdivision,
    applyStickingPreset,
    applyVoicePreset,
    setTimeSignatureTop,
    setTimeSignatureBottom,
  },
}: {
  groove: Groove;
  grooveHooks: GrooveHooks;
}) {
  const grooveVoiceIds = useMemo(
    () =>
      unique(
        groove.bars
          .map((bar) =>
            bar.beats.map((beat) => beat.map((sub) => sub.notes.map((note) => note.voiceId)))
          )
          .flat(3)
      ),
    [groove]
  );
  const [showAllVoices, setShowAllVoices] = useState(false);
  const activeVoices = voices.filter(
    (v) => showAllVoices || grooveVoiceIds.includes(v.id) || !v.extra
  );

  return (
    <div className="bg-slate-900 flex gap-px">
      {/* sidebar */}
      <div className="min-w-fit flex flex-col gap-px">
        {/* time sig */}
        <div className="h-8 flex items-center gap-px">
          <select
            className="w-full border-0 bg-slate-600 text-white py-1.5 pr-8 text-sm focus:ring-1 focus:ring-slate-500"
            value={groove.timeSignatureTop}
            onChange={(e) => setTimeSignatureTop(parseInt(e.target.value))}
          >
            {range(1, 10).map((i) => (
              <option key={i} value={i}>
                {i}
              </option>
            ))}
          </select>
          <select
            className="w-full border-0 bg-slate-600 text-white py-1.5 pr-8 text-sm focus:ring-1 focus:ring-slate-500"
            value={groove.timeSignatureBottom}
            onChange={(e) => setTimeSignatureBottom(parseInt(e.target.value))}
          >
            {[4, 8].map((i) => (
              <option key={i} value={i}>
                {i}
              </option>
            ))}
          </select>
        </div>

        {/* subdivision */}
        <DropdownMenu.Root>
          <DropdownMenu.Trigger className="h-8 w-full flex items-center justify-end bg-slate-600 text-white hover:bg-slate-500 px-2 py-0.5 text-sm focus:outline-none focus:ring-1 focus:ring-slate-500">
            Subdivision
          </DropdownMenu.Trigger>
          <DropdownMenu.Portal>
            <DropdownMenuContent>
              {subdivisions.map((subdivision) => (
                <DropdownMenuItem
                  key={subdivision}
                  onSelect={() => setGrooveSubdivision(subdivision)}
                >
                  {subdivisionName(subdivision)}
                </DropdownMenuItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu.Portal>
        </DropdownMenu.Root>

        {/* sticking */}
        <DropdownMenu.Root>
          <DropdownMenu.Trigger className="h-8 w-full flex items-center justify-end bg-slate-600 text-white hover:bg-slate-500 px-2 py-0.5 text-sm focus:outline-none focus:ring-1 focus:ring-slate-500">
            Sticking
          </DropdownMenu.Trigger>
          <DropdownMenu.Portal>
            <DropdownMenuContent>
              {stickingPresets.map(([preset, name]) => (
                <DropdownMenuItem key={preset} onSelect={() => applyStickingPreset(preset)}>
                  {name}
                </DropdownMenuItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu.Portal>
        </DropdownMenu.Root>

        {/* voices */}
        {activeVoices.map((voice) => (
          <DropdownMenu.Root key={voice.id}>
            <DropdownMenu.Trigger className="h-8 w-full flex items-center justify-end bg-slate-600 text-white hover:bg-slate-500 px-2 py-0.5 text-sm focus:outline-none focus:ring-1 focus:ring-slate-500">
              {voice.name}
            </DropdownMenu.Trigger>
            <DropdownMenu.Portal>
              <DropdownMenuContent>
                {voicePresets.map(([preset, name]) => (
                  <DropdownMenuItem key={preset} onSelect={() => applyVoicePreset(voice, preset)}>
                    {name}
                  </DropdownMenuItem>
                ))}
              </DropdownMenuContent>
            </DropdownMenu.Portal>
          </DropdownMenu.Root>
        ))}

        <button
          type="button"
          onClick={() => setShowAllVoices((prev) => !prev)}
          className="h-8 w-full flex items-center justify-end bg-slate-600 text-white hover:bg-slate-500 px-2 py-0.5 text-sm focus:outline-none focus:ring-1 focus:ring-slate-500"
        >
          {showAllVoices ? (
            <ChevronUpIcon className="h-4 w-4" />
          ) : (
            <ChevronDownIcon className="h-4 w-4" />
          )}
        </button>
      </div>
      {/* bars */}
      <div className="overflow-x-auto">
        <div className="h-full flex gap-px">
          {groove.bars.map((bar, barIdx) => (
            <section key={barIdx} className="flex flex-col gap-px">
              {/* header */}
              <header className="h-8 w-full px-2 flex items-center justify-between gap-2 bg-slate-600 text-white">
                <button
                  type="button"
                  onClick={() => moveBar(barIdx, barIdx - 1)}
                  className="flex disabled:opacity-25"
                  disabled={barIdx === 0}
                >
                  <ChevronLeftIcon className="h-4 w-4" />
                </button>

                <div className="flex items-center space-x-3">
                  <div className="flex space-x-0.5">
                    <button
                      onClick={() => duplicateBar(barIdx)}
                      x-tooltip="Duplicate"
                      type="button"
                      className="rounded p-1 text-white hover:bg-slate-500"
                    >
                      <DocumentDuplicateIcon className="h-4 w-4" />
                    </button>

                    <button
                      onClick={() => revertBar(barIdx)}
                      x-tooltip="Undo all"
                      type="button"
                      className="rounded p-1 text-white hover:bg-slate-500"
                    >
                      <ArrowUturnLeftIcon className="h-4 w-4" />
                    </button>
                  </div>

                  <h1>{barIdx + 1}</h1>

                  <div className="flex space-x-0.5">
                    <button
                      onClick={() => clearBar(barIdx)}
                      x-tooltip="Clear"
                      type="button"
                      className="rounded p-1 text-white hover:bg-slate-500"
                    >
                      <BackspaceIcon className="h-4 w-4" />
                    </button>

                    <button
                      onClick={() => deleteBar(barIdx)}
                      x-tooltip="Delete"
                      type="button"
                      className="rounded p-1 text-white hover:bg-slate-500"
                    >
                      <TrashIcon className="h-4 w-4" />
                    </button>
                  </div>
                </div>

                <button
                  type="button"
                  onClick={() => moveBar(barIdx, barIdx + 1)}
                  className="flex disabled:opacity-25"
                  disabled={barIdx === groove.bars.length - 1}
                >
                  <ChevronRightIcon className="h-4 w-4" />
                </button>
              </header>

              {/* beats */}
              <div x-ignore="true" className="flex gap-px">
                {bar.beats.map((beat, beatIdx) => (
                  <div key={beatIdx} className="flex flex-col gap-px">
                    {/* subdivision */}
                    <DropdownMenu.Root>
                      <DropdownMenu.Trigger className="h-8 w-full bg-slate-600 text-white hover:bg-slate-500 px-2 py-0.5 text-sm focus:outline-none focus:ring-1 focus:ring-slate-500">
                        <span className="font-semibold">{beatIdx + 1}</span>
                        <sup className="ml-0.5 text-slate-400">
                          {subdivisionName(getSubdivision(groove.timeSignatureBottom, beat))}
                        </sup>
                      </DropdownMenu.Trigger>
                      <DropdownMenu.Portal>
                        <DropdownMenuContent>
                          {subdivisions.map((subdivision) => (
                            <DropdownMenuItem
                              key={subdivision}
                              onSelect={() => setBeatSubdivision(barIdx, beatIdx, subdivision)}
                            >
                              {subdivisionName(subdivision)}
                            </DropdownMenuItem>
                          ))}
                        </DropdownMenuContent>
                      </DropdownMenu.Portal>
                    </DropdownMenu.Root>

                    {/* sticking */}
                    <div className="flex items-center gap-px">
                      {beat.map(({ notes, sticking }, subIdx) => {
                        return (
                          <ContextMenu.Root key={subIdx}>
                            <ContextMenu.Trigger asChild disabled={!sticking && !notes.length}>
                              <button
                                type="button"
                                onClick={() => toggleSticking(barIdx, beatIdx, subIdx)}
                                disabled={!sticking && !notes.length}
                                className={cn(
                                  "h-8 w-8 bg-slate-800",
                                  sticking ? "text-white" : "text-slate-600 hover:text-slate-500"
                                )}
                              >
                                <span className="select-none">
                                  {sticking ?? (notes.length ? getDefaultSticking(notes) : "")}
                                </span>
                              </button>
                            </ContextMenu.Trigger>
                            <ContextMenu.Portal>
                              <ContextMenuContent>
                                {stickings.map((stickingOption) => (
                                  <ContextMenuItem
                                    key={stickingOption}
                                    onSelect={() =>
                                      setSticking(barIdx, beatIdx, subIdx, stickingOption)
                                    }
                                    selected={sticking === stickingOption}
                                  >
                                    {stickingName(stickingOption)}
                                  </ContextMenuItem>
                                ))}
                                {sticking && (
                                  <ContextMenuItem
                                    onSelect={() => setSticking(barIdx, beatIdx, subIdx, null)}
                                  >
                                    Off
                                  </ContextMenuItem>
                                )}
                              </ContextMenuContent>
                            </ContextMenu.Portal>
                          </ContextMenu.Root>
                        );
                      })}
                    </div>

                    {/* voices */}
                    {activeVoices.map((voice) => (
                      <div key={voice.id} className="flex items-center gap-px">
                        {beat.map(({ notes }, subIdx) => {
                          const note = notes.find((n) => n.voiceId === voice.id);

                          return (
                            <ContextMenu.Root key={subIdx}>
                              <ContextMenu.Trigger asChild>
                                <button
                                  type="button"
                                  onClick={() => toggleNote(barIdx, beatIdx, subIdx, voice)}
                                  className={cn(
                                    "h-8 w-8 text-white",
                                    note
                                      ? "bg-green-500"
                                      : subIdx === 0
                                      ? "bg-slate-700 hover:bg-green-500/50"
                                      : "bg-slate-800 hover:bg-green-500/50"
                                  )}
                                >
                                  {note?.variantId
                                    ? variants.find((v) => v.id === note.variantId)?.icon
                                    : ""}
                                </button>
                              </ContextMenu.Trigger>
                              <ContextMenu.Portal>
                                <ContextMenuContent>
                                  {Object.entries(voice.variants)
                                    .map(([variantId, options]) => ({
                                      variant: variants.find((v) => v.id === variantId)!,
                                      options,
                                    }))
                                    .map(({ variant }) => (
                                      <ContextMenuItem
                                        key={variant.id}
                                        onSelect={() =>
                                          setNote(barIdx, beatIdx, subIdx, {
                                            voiceId: voice.id,
                                            variantId: variant.id,
                                          })
                                        }
                                        selected={note?.variantId === variant.id}
                                      >
                                        <span className="flex-1">{variant.name}</span>
                                        <span className="text-xs tracking-widest text-neutral-400 group-hover:text-neutral-600">
                                          {variant.icon}
                                        </span>
                                      </ContextMenuItem>
                                    ))}
                                  <ContextMenuItem
                                    onSelect={() =>
                                      setNote(barIdx, beatIdx, subIdx, {
                                        voiceId: voice.id,
                                        variantId: null,
                                      })
                                    }
                                    selected={note && !note.variantId}
                                  >
                                    Normal
                                  </ContextMenuItem>
                                  <ContextMenuItem
                                    onSelect={() => toggleNote(barIdx, beatIdx, subIdx, voice)}
                                    selected={!note}
                                  >
                                    Off
                                  </ContextMenuItem>
                                </ContextMenuContent>
                              </ContextMenu.Portal>
                            </ContextMenu.Root>
                          );
                        })}
                      </div>
                    ))}

                    {/* subdivision labels */}
                    <div className="flex items-center gap-px">
                      {beat.map(({ notes }, subIdx) => (
                        <div key={subIdx} className="h-8 w-8 flex items-center justify-center">
                          <span
                            className={cn(
                              "text-xs font-medium",
                              subIdx > 0 ? "text-slate-600" : "text-slate-400"
                            )}
                          >
                            {subIdx === 0
                              ? beatIdx + 1
                              : getSubdivisionCounter(
                                  subIdx,
                                  getSubdivision(groove.timeSignatureBottom, beat)
                                )}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </section>
          ))}

          <button
            type="button"
            onClick={() => addBar()}
            className="w-full flex items-center justify-center bg-slate-600 text-white px-2 hover:bg-slate-500"
            title="Add bar"
          >
            <PlusIcon className="h-6 w-6" />
          </button>
        </div>
      </div>
    </div>
  );
}

function DropdownMenuContent({ ...props }) {
  return (
    <DropdownMenu.Content
      align="start"
      className="py-1 min-w-[120px] bg-white border border-gray-200 shadow-md outline-none"
      {...props}
    />
  );
}

function DropdownMenuSubTrigger({ ...props }) {
  return (
    <DropdownMenu.SubTrigger
      className="px-3 py-1.5 text-sm w-full group flex items-center justify-between space-x-4 cursor-pointer select-none hover:bg-neutral-100 focus:bg-slate-100 focus:outline-none data-[disabled]:opacity-50 data-[disabled]:pointer-events-none"
      {...props}
    />
  );
}

function DropdownMenuSubContent({ ...props }) {
  return (
    <DropdownMenu.SubContent
      className="py-1 min-w-[120px] bg-white border border-gray-200 shadow-md outline-none"
      {...props}
    />
  );
}

function DropdownMenuItem({ ...props }) {
  return (
    <DropdownMenu.Item
      className="px-3 py-1.5 text-sm w-full group flex items-center justify-between space-x-4 cursor-pointer select-none hover:bg-neutral-100 focus:bg-slate-100 focus:outline-none data-[disabled]:opacity-50 data-[disabled]:pointer-events-none"
      {...props}
    />
  );
}

function ContextMenuContent({ ...props }) {
  return (
    <ContextMenu.Content
      className="py-1 min-w-[120px] bg-white border border-gray-200 shadow-md outline-none"
      {...props}
    />
  );
}

function ContextMenuSubContent({ ...props }) {
  return (
    <ContextMenu.SubContent
      className="py-1 min-w-[120px] bg-white border border-gray-200 shadow-md outline-none"
      {...props}
    />
  );
}

function ContextMenuItem({ selected = false, ...props }) {
  return (
    <ContextMenu.Item
      className={cn(
        "px-3 py-1.5 text-sm w-full group flex items-center justify-between space-x-4 cursor-pointer select-none hover:bg-neutral-100 focus:bg-slate-100 focus:outline-none data-[disabled]:opacity-50 data-[disabled]:pointer-events-none",
        selected && "font-medium"
      )}
      disabled={selected}
      {...props}
    />
  );
}
